
import {Component, Vue} from 'vue-property-decorator'
import left from "@/components/left.vue"
import itemDetail from "@/components/itemDetail.vue"
@Component({
    components:{ 
        left,itemDetail
    },
})
export default class Detail extends Vue{
}
